var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "盘点汇总",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', [_c('div', {
    staticClass: "filter-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "日期",
      "value": _vm.listQuery.date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "类型",
      "value": _vm.typeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showTypeActions = true;
      }
    }
  })], 1), _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (stocktaking) {
    return _c('div', {
      key: stocktaking.id,
      staticClass: "stock-record-container"
    }, [_c('van-cell', {
      attrs: {
        "title": "门店",
        "value": stocktaking.store ? stocktaking.store.name : ''
      }
    }), stocktaking.lose_amount != 0 ? _c('van-cell', {
      attrs: {
        "value-class": "amount",
        "title": "金额",
        "value": '￥' + stocktaking.lose_amount
      }
    }) : _vm._e(), stocktaking.last_at ? _c('van-cell', {
      attrs: {
        "value": stocktaking.last_at
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('span', [_vm._v("上次盘点")]), _c('span', {
            staticClass: "last-at-subtitle"
          }, [_vm._v("(基于选择的日期)")])];
        },
        proxy: true
      }], null, true)
    }) : _vm._e(), _c('div', {
      staticClass: "goods"
    }, [_c('div', {
      staticClass: "goods-image"
    }, [_c('van-image', {
      attrs: {
        "src": stocktaking.goods.product.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(stocktaking.goods.product ? stocktaking.goods.product.name : ''))]), _c('div', {
      staticClass: "goods-size"
    }, [_vm._v("规格：1 *" + _vm._s(stocktaking.goods.product.size))])])]), _c('van-cell', {
      attrs: {
        "title": "盘点记录",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toWarehouseGoodsDetailView(stocktaking.goods_id);
        }
      }
    }), _c('van-cell', {
      attrs: {
        "title": "库存记录",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toWarehouseGoodsDetailView2(stocktaking.goods_id);
        }
      }
    })], 1);
  })], 2), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.selectDate,
      "cancel": function cancel($event) {
        _vm.show = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.selectAction
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  }), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.typeActions
    },
    on: {
      "select": _vm.selectTypeAction
    },
    model: {
      value: _vm.showTypeActions,
      callback: function callback($$v) {
        _vm.showTypeActions = $$v;
      },
      expression: "showTypeActions"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }