<template>
  <div>
    <my-nav-bar
      title="盘点汇总"
      left-text="返回"
      left-arrow
    />
    <div>
      <div class="filter-container">
        <van-cell title="日期" :value="listQuery.date" is-link center @click="show = true" />
        <van-cell title="门店" :value="storeName" is-link center @click="showActions = true" />
        <van-cell title="类型" :value="typeName" is-link center @click="showTypeActions = true" />
      </div>
      <van-list
        ref="list"
        v-model="loading"
        class="list"
        :finished="finished"
        @load="getList"
      >
        <van-empty v-if="showEmpty" description="暂无数据" />
        <div v-for="stocktaking in list" :key="stocktaking.id" class="stock-record-container">
          <van-cell title="门店" :value="stocktaking.store ? stocktaking.store.name : ''" />
          <van-cell v-if="stocktaking.lose_amount != 0" value-class="amount" title="金额" :value="'￥'+stocktaking.lose_amount" />
          <!-- <van-cell v-if="stocktaking.is_unusual" title="盘点状态" value-class="amount" value="异常" /> -->
          <van-cell v-if="stocktaking.last_at" :value="stocktaking.last_at">
            <template #title>
              <span>上次盘点</span>
              <span class="last-at-subtitle">(基于选择的日期)</span>
            </template>
          </van-cell>
          <div class="goods">
            <div class="goods-image">
              <van-image :src="stocktaking.goods.product.image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ stocktaking.goods.product ? stocktaking.goods.product.name : '' }}</div>
              <div class="goods-size">规格：1 *{{ stocktaking.goods.product.size }}</div>
            </div>
          </div>
          <!-- <van-cell title="盘点详情" is-link center @click="toDetailView(stocktaking.goods_id)" /> -->
          <van-cell title="盘点记录" is-link center @click="toWarehouseGoodsDetailView(stocktaking.goods_id)" />
          <van-cell title="库存记录" is-link center @click="toWarehouseGoodsDetailView2(stocktaking.goods_id)" />
        </div>
      </van-list>
      <van-popup v-model="show" position="bottom">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择日期"
          @confirm="selectDate"
          @cancel="show = false"
        />
      </van-popup>
      <van-action-sheet v-model="showActions" :actions="actions" @select="selectAction" />
      <van-action-sheet v-model="showTypeActions" :actions="typeActions" @select="selectTypeAction" />
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { toDateString } from '@/utils'
import { getStockRecord } from '@/api/stock-record'
import { getStores } from '@/api/home'
export default {
  name: 'StockRecord',
  components: { myNavBar },
  data() {
    return {
      currentDate: new Date(),
      listQuery: {
        page: 0,
        date: this.$route.query.date || toDateString(new Date()),
        store_id: this.$route.query.store_id || '',
        type: ''
      },
      storeName: '全部',
      typeName: '全部',
      list: [],
      loading: false,
      finished: false,
      showEmpty: false,
      show: false,
      showActions: false,
      showTypeActions: false,
      actions: [
        { value: 0, name: '全部' }
      ],
      typeActions: [
        { name: '全部', value: 0 },
        { name: '循环盘点', value: 1 },
        { name: '出货盘点', value: 2 },
        { name: '退货盘点', value: 3 },
        { name: '其它盘点', value: 4 }
      ]
    }
  },
  created() {
    // 缓存当前页面
    this.$store.commit('setKeepAlive', this.$options.name)

    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          value: store.id,
          name: store.name
        })
      })

      if (this.$route.query.store_id) {
        const store = this.actions.find(action => action.value === Number(this.$route.query.store_id))
        this.storeName = store.name
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path !== '/stock-record-detail' && to.path !== '/stock-taking-record' && to.path !== '/stock-logs') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    selectDate(date) {
      this.listQuery.date = toDateString(date)
      this.show = false
      this.reload()
    },
    selectAction(action) {
      this.listQuery.store_id = action.value
      this.storeName = action.name
      this.showActions = false
      this.reload()
    },
    selectTypeAction(action) {
      this.listQuery.type = action.value
      this.typeName = action.name
      this.showTypeActions = false
      this.reload()
    },
    getList() {
      this.listQuery.page++
      getStockRecord(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list < 20
        this.showEmpty = this.list.length === 0
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    toDetailView(goodsId) {
      this.$router.push({
        path: '/stock-record-detail',
        query: {
          store_id: this.listQuery.store_id,
          date: this.listQuery.date,
          goods_id: goodsId
        }
      })
    },
    toWarehouseGoodsDetailView(goodsId) {
      this.$router.push({
        path: '/stock-taking-record',
        query: { goods_id: goodsId }
      })
    },
    toWarehouseGoodsDetailView2(goodsId) {
      this.$router.push({
        path: '/stock-logs',
        query: { goods_id: goodsId }
      })
    },
    toDataAnalysisView(goodsId, lastStocktakingAt) {
      this.$router.push({
        path: '/stock-record-data-analysis',
        query: {
          goods_id: goodsId,
          last_at: lastStocktakingAt
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "/src/styles/color";
  .filter-container {
    position: fixed;
    left: 0;
    top: 46px;
    right: 0;
    z-index: 9;
  }
  .list {
    margin-top: 188px;
    padding: 0 10px;
  }
  .stock-record-container {
    margin-bottom: 10px;
  }
  .goods {
    position: relative;
    display: flex;
    background-color: #fff;
    padding: 10px;
  }
  .goods::after {
    position: absolute;
    box-sizing: border-box;
    content: ' ';
    pointer-events: none;
    right: 0.42667rem;
    bottom: 0;
    left: 0.42667rem;
    border-bottom: 0.02667rem solid #ebedf0;
    -webkit-transform: scaleY(.5);
    transform: scaleY(.5);
  }
  .goods-image {
    width: 100px;
    height: 100px;
  }
  .goods-image .van-image {
    width: 100px;
    height: 100px;
  }
  .goods-info {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
  }
  .goods-name {
    font-size: 16px;
  }
  .goods-size {
    margin-top: 10px;
    font-size: 13px;
    color: #989898;
  }
  .amount {
    color: $amountRedColor;
  }
  .last-at-subtitle {
    font-size: 10px;
    color: #989898;
  }
</style>
