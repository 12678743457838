import request from '@/utils/request'

export function getStockRecord(params) {
  return request({
    method: 'get',
    url: 'stock-record',
    params
  })
}

export function getStockRecordDetail(params) {
  return request({
    method: 'get',
    url: 'stock-record/detail',
    params
  })
}
